import Border from "@atoms/Border";
import React from "react";
import styles from "./videoplayer.module.scss";

const VideoPlayer = ({ source, title, clase }) => {
  return (
    <>
      <div className={styles.video}>
        <Border className={styles.videoBorder}>
          <iframe
            title="video"
            className={styles.iframe}
            src={source}
            width='640'
            height='360'
            frameborder='0'
            allow='autoplay; fullscreen; picture-in-picture'
            allowfullscreen
          ></iframe>
        </Border>
      </div>
    </>
  );
};

export default VideoPlayer;
