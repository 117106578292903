import React, { useEffect, useState } from "react";
import SmokeLight from "@atoms/SmokeLight";
import VideoPlayer from "@molecules/VideoPlayer";
import styles from "./how-is-it.module.scss";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import DividerCenter from "@atoms/DividerCenter";
import { motion } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { useTranslation } from "gatsby-plugin-react-i18next";

const greenSmoke =
  "radial-gradient(39.91% 42.1% at 46.34% 47.34%, rgba(4, 230, 167, 0.22) 0%, rgba(4, 6, 20, 0) 100%)";

const initialAnimation = { opacity: 0, y: 20 };

const HowIsIt = () => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation();

  const [thIsIsUsRef, isThisIsUsVisible] = useOnScreen({ threshold: 0.1 });
  const [animation, setAnimation] = useState(initialAnimation);

  useEffect(() => {
    if (isThisIsUsVisible) {
      setAnimation({ opacity: 1, y: 0 });
    }
  }, [isThisIsUsVisible]);

  return (
    <section className={`${styles.section} text-center`} id='thisIsUs'>
      <div className={styles.greenSmoke}>
        <SmokeLight
          background={greenSmoke}
          width={breakpoints.sm ? 700 : 1640}
          height={breakpoints.sm ? 500 : 1200}
        />
      </div>
      {breakpoints.sm && <DividerCenter />}
      <VideoPlayer source={t("WORK.VIDEO")} title="N5 Now" />
      <motion.h2
        ref={thIsIsUsRef}
        animate={animation}
        transition={{ type: "spring", stiffness: 20, delay: 0.3 }}
        className={styles.title}
      >
        {t("WORK.USTITLE")}
      </motion.h2>
      <motion.p
        ref={thIsIsUsRef}
        animate={animation}
        transition={{ type: "spring", stiffness: 20, delay: 0.4 }}
        className={styles.description}
      >
        {t("WORK.USSUBTITLE")}
      </motion.p>
      <motion.p
        ref={thIsIsUsRef}
        animate={animation}
        transition={{ type: "spring", stiffness: 20, delay: 0.5 }}
        className={styles.descriptionSpan}
      >
        {t("WORK.BEPART")}
      </motion.p>
    </section>
  );
};

export default HowIsIt;
